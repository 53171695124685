import React, { useContext, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { SessionContext } from '../providers/SessionProvider/context';
import ActivityIndicator from '../components/indicators';
import { UIView } from '../utils/styled/styled';
import Cases from '../screens/Cases';
import Home from '../screens/Home';
import CaseDetails from '../screens/Cases/CaseDetails';
import Calendar from '../screens/Calendar';
import PortalJust from '../screens/PortalJust';
import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';
import ForgotPassword from '../screens/Auth/ForgotPassword';
import VerifyPhoneNumber from '../screens/Auth/VerifyPhoneNumber';
import Users from '../screens/admin/Users';
import UserDetails from '../screens/admin/UserDetails';
import Profile from '../screens/Profile';
import Subscriptions from '../screens/Subscriptions';
import CaseActivityLog from '../screens/Cases/CaseActivityLog';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const _Routes = () => {
  const query = useQuery();
  const signUpCampaign = query.get('signup_campaign');
  const signUpCampaignId = query.get('signup_campaign_id');

  const { userIsAdmin, isLoggedIn, doesVerifyPhoneNumber, setSignUpCampaignDetails } = useContext(SessionContext);

  useEffect(() => {
    if (signUpCampaign && signUpCampaign?.length && signUpCampaignId && signUpCampaignId?.length) {
      setSignUpCampaignDetails(signUpCampaign, signUpCampaignId);
    }
  }, []);

  return isLoggedIn && !doesVerifyPhoneNumber ? (
    <Routes>
      <Route path={'/'} element={<Home />} />
      <Route path={'/dosare'} element={<Cases />} />
      <Route path={'/dosare/:id'} element={<CaseDetails />} />
      <Route
        path={'/activitate-dosar/:id'}
        element={userIsAdmin && isLoggedIn ? <CaseActivityLog /> : <Navigate to={'/'} replace />}
      />
      <Route path={'/calendar'} element={<Calendar />} />
      <Route path={'/portal-just'} element={<PortalJust />} />
      <Route path={'/users'} element={userIsAdmin && isLoggedIn ? <Users /> : <Navigate to={'/'} replace />} />
      <Route
        path={'/users/:id'}
        element={userIsAdmin && isLoggedIn ? <UserDetails /> : <Navigate to={'/'} replace />}
      />
      <Route path={'/contul-meu'} element={<Profile />} />
      <Route path={'/abonamente'} element={<Subscriptions />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />
      <Route path={'/forgot-password'} element={<ForgotPassword />} />
      <Route path={'/verify-phone-number'} element={<VerifyPhoneNumber />} />
      <Route path={'*'} element={<Navigate to={'/login'} replace />} />
    </Routes>
  );
};

const ProjectRoutes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <UIView displayFlex alignItems='center' justifyContent='center' fullWidth fullScreenHeight>
            <ActivityIndicator />
          </UIView>
        }>
        <_Routes />
      </Suspense>
    </Router>
  );
};

export default ProjectRoutes;
