import React, { useMemo } from 'react';
import { Components } from './styled';
import { Case } from '../../../../../api/types/case';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../../../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../../../utils/responsive/constants';
import { parseDateToStringWithFormat } from '../../../../../utils/dateFormat';
import { format } from '../../../../../utils/dateFormat/types';
import Parties from '../../../../../components/caseComponents/Parties';
import { ReactComponent as PinIcon } from '../../../../../assets/icons/icon-pin.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/icon-arrow-right.svg';
import Placeholder from '../../../../../components/Placeholder';

const TomorrowCases = ({ cases }: { cases: Case[] }) => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const seeInCaseDetails = (caseId?: string) => {
    if (caseId) {
      navigate(`/dosare/${caseId}`);
    }
  };

  const hearings = useMemo(
    () =>
      cases
        ?.map((item) => ({ ...item?.hearings?.[0], case: item, caseId: item?.id }))
        ?.sort((a, b) => new Date(a?.portalDate)?.getTime() - new Date(b?.portalDate)?.getTime()),
    [cases],
  );
  return (
    <Components.Container isMobile={isMobile}>
      <Components.Title>Termene pentru următoarea săptămână</Components.Title>
      <Components.HearingsWrapper>
        {hearings && hearings?.length > 0 ? (
          hearings?.map((item, key) => (
            <Components.HearingContainer key={key}>
              <Components.HearingContent>
                <Components.HearingTitleWrapper>
                  <Components.HearingTitle>
                    {item?.portalDate &&
                      parseDateToStringWithFormat(item?.portalDate, format.weekDayMonthDay)?.concat(', ', item?.hour)}
                  </Components.HearingTitle>
                  <Components.HearingTag>TERMEN</Components.HearingTag>
                </Components.HearingTitleWrapper>
                {item?.case && (
                  <>
                    <Components.HearingCaseNumberWrapper isMobile={isMobile}>
                      <Components.HearingCaseNumber onClick={() => seeInCaseDetails(item?.caseId)}>
                        {item?.case?.displayNumber}
                      </Components.HearingCaseNumber>
                      {!isMobile && <Components.Separator />}
                      <Parties parties={item?.case?.parties} />
                    </Components.HearingCaseNumberWrapper>
                    <Components.LocationAndPanelWWrapper isMobile={isMobile}>
                      <Components.LocationWrapper>
                        <PinIcon height={20} width={20} />
                        <Components.LocationLabel>{item?.case?.displayInstitution}</Components.LocationLabel>
                      </Components.LocationWrapper>
                      {item?.panel && (
                        <>
                          {!isMobile && <Components.Separator />}
                          <Components.LocationLabel>{item?.panel}</Components.LocationLabel>
                        </>
                      )}
                    </Components.LocationAndPanelWWrapper>
                  </>
                )}
              </Components.HearingContent>
              <Components.ButtonsWrapper>
                <Components.SeeInCaseButton onClick={() => seeInCaseDetails(item?.caseId)}>
                  Vezi în dosar
                  <ArrowRightIcon />
                </Components.SeeInCaseButton>
              </Components.ButtonsWrapper>
            </Components.HearingContainer>
          ))
        ) : (
          <Placeholder text={'Nu există evenimente ce pot fi afișate'} />
        )}
      </Components.HearingsWrapper>
    </Components.Container>
  );
};

export default TomorrowCases;
