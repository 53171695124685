export const ENDPOINTS = {
  messaging: {
    sendPhoneVerificationCode: '/messaging/send-phone-verification-code',
    verifyPhoneNumber: '/messaging/verify-phone-number',
  },
  profile: {
    getById: (id: string) => `/profile/get/${id}`,
    create: '/profile/create',
    archiveCase: '/profile/archive-case',
    saveCaseParty: '/profile/save-case-party',
    finishOnboarding: (profileId: string) => `/profile/finish-onboarding/${profileId}`,
    updateGenericNotifications: '/profile/update-generic-notifications',
  },
  case: {
    searchPortalCase: '/case/search-portal-case',
    savePortalCase: '/case/save-portal-case',
    saveMultipleCases: '/case/save-multiple-cases',
    getAll: '/case/get-all',
    getLatest: '/case/get-latest',
    getById: (id: string) => `/case/get/${id}`,
  },
  calendar: {
    getAll: '/calendar/get-all',
    getUpcoming: '/calendar/get-upcoming',
  },
  admin: {
    users: {
      getAll: '/admin/user/get-all',
      getById: (id: string) => `/admin/user/get/${id}`,
      update: '/admin/user/update',
    },
    cases: {
      getCaseLogs: '/admin/case/get-logs',
    },
  },
  subscription: {
    createCheckoutSession: '/stripe/checkout',
    createCustomerPortalSession: '/stripe/customer-portal',
  },
  note: {
    addNote: '/note/add-note',
    getAll: '/note/get-all',
  },
};
