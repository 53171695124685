import { Case, CaseSyncLog } from '../types/case';
import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { parseCase, parseCases } from './parser';
import { ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorFromRawError } from '../../utils/errors';

export const getAllCases = async (profileCaseIds: string[]): Promise<Case[]> => {
  try {
    const result = await apiFetch({ path: ENDPOINTS.case.getAll, method: 'POST', body: { caseIds: profileCaseIds } });
    return await parseCases(result?.data);
  } catch (e) {
    console.log('[getAllCases] Error:', e);
    return [];
  }
};

export const getLatestCases = async (profileCaseIds: string[]): Promise<Case[]> => {
  try {
    const result = await apiFetch({
      path: ENDPOINTS.case.getLatest,
      method: 'POST',
      body: { caseIds: profileCaseIds },
    });
    return await parseCases(result?.data);
  } catch (e) {
    console.log('[getLatestCases] Error:', e);
    return [];
  }
};

export const getCaseById = async (caseId: string): Promise<Case | ProjectErrorType> => {
  try {
    const result = await apiFetch({ path: ENDPOINTS.case.getById(caseId), method: 'GET' });
    return await parseCase(result?.data);
  } catch (e) {
    console.log('[getAllCases] Error:', e);
    return getProjectErrorFromRawError(e);
  }
};

export const getCaseLogs = async (caseId: string): Promise<CaseSyncLog[] | ProjectErrorType> => {
  try {
    const result = await apiFetch({ path: ENDPOINTS.admin.cases.getCaseLogs, method: 'POST', body: { caseId } });
    return result?.data;
  } catch (e) {
    console.log('[getAllCases] Error:', e);
    return getProjectErrorFromRawError(e);
  }
};
