import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Components } from './styled';
import SideBarLink from './SideBarLink';

import { ReactComponent as Logo } from '../../assets/logo/logo-horizontal-white.svg';
import { ReactComponent as HomeIconActive } from '../../assets/sidebarIcons/icon-home-route-active.svg';
import { ReactComponent as HomeIcon } from '../../assets/sidebarIcons/icon-home-route.svg';
import { ReactComponent as CalendarIconActive } from '../../assets/sidebarIcons/icon-calendar-route-active.svg';
import { ReactComponent as CalendarIcon } from '../../assets/sidebarIcons/icon-calendar-route.svg';
import { ReactComponent as CasesIconActive } from '../../assets/sidebarIcons/icon-cases-route-active.svg';
import { ReactComponent as CasesIcon } from '../../assets/sidebarIcons/icon-cases-route.svg';
import { ReactComponent as PortalIconActive } from '../../assets/sidebarIcons/icon-portal-route-active.svg';
import { ReactComponent as PortalIcon } from '../../assets/sidebarIcons/icon-portal-route.svg';
import { ReactComponent as UsersIcon } from '../../assets/sidebarIcons/icon-users-route.svg';
import { ReactComponent as UsersIconActive } from '../../assets/sidebarIcons/icon-users-route-active.svg';
import { isProductionEnvironment } from '../../defines';
import { SessionContext } from '../../providers/SessionProvider/context';

const ProjectSideBar = () => {
  const navigate = useNavigate();

  const { userIsAdmin } = useContext(SessionContext);

  const onLogoPress = () => {
    navigate('/');
  };

  return (
    <Components.Container>
      <Components.LogoContainer onClick={onLogoPress}>
        <Logo />
      </Components.LogoContainer>
      <Components.InnerContainer>
        <SideBarLink label={'Acasă'} to={'/'} IconActive={<HomeIconActive />} Icon={<HomeIcon />} activeOnlyWhenExact />
        <SideBarLink
          label={'Calendar'}
          to={'/calendar'}
          IconActive={<CalendarIconActive />}
          Icon={<CalendarIcon />}
          activeOnlyWhenExact
        />
        <SideBarLink label={'Dosarele mele'} to={'/dosare'} IconActive={<CasesIconActive />} Icon={<CasesIcon />} />
        <SideBarLink
          label={'Portal Just'}
          to={'/portal-just'}
          IconActive={<PortalIconActive />}
          Icon={<PortalIcon />}
          activeOnlyWhenExact
        />
        {userIsAdmin && (
          <>
            <Components.Separator />
            <SideBarLink label={'Utilizatori'} to={'/users'} Icon={<UsersIcon />} IconActive={<UsersIconActive />} />
          </>
        )}
        {!isProductionEnvironment && <Components.VersionLabel>Versiune de test</Components.VersionLabel>}
      </Components.InnerContainer>
    </Components.Container>
  );
};

export default ProjectSideBar;
