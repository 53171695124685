import React from 'react';
import { UIView } from '../../../utils/styled/styled';
import { Components } from './styled';
import ProjectSideBar from '../../ProjectSideBar';
import ProjectHeaderBar from '../../ProjectHeaderBar';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { SIDEBAR_WIDTH } from '../../../utils/styled/dimensions';
import { createMediaQuery } from '../../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/responsive/constants';
import TrialBar from '../../TrialBar';
import CampaignBar from '../../CampaignBar';
import WarningBar from '../../WarningBar';

const ProjectContainer = ({
  children,
  hasBack,
  title,
  secondaryTitle,
}: {
  children?: React.ReactNode;
  hasBack?: boolean | string;
  title?: string;
  secondaryTitle?: string;
}) => {
  const isDesktop = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.desktop),
  });

  return (
    <UIView fullWidth fullScreenHeight displayFlex>
      <MediaQuery minWidth={MEDIA_QUERY_BREAKPOINTS.desktop}>
        <ProjectSideBar />
      </MediaQuery>

      <Components.RightSideContainer
        autoHeight
        displayFlex
        flexDirection='column'
        addedWidht={isDesktop ? SIDEBAR_WIDTH : 0}>
        <ProjectHeaderBar title={title} secondaryTitle={secondaryTitle} hasBack={hasBack} />
        <TrialBar />
        <CampaignBar />
        <Components.ChildrenWrapper>{children}</Components.ChildrenWrapper>
      </Components.RightSideContainer>
    </UIView>
  );
};

export default ProjectContainer;
