import styled from 'styled-components';
import AppCSS from '../../../utils/styled';
import Checkbox from '@mui/material/Checkbox';

export const Components = {
  Container: styled.div<{ isLaptop: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px ${(p) => (p.isLaptop ? 24 : 16)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 32px;
  `,
  HeaderContainer: styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  `,
  HeaderLeftSideWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `,
  HeaderRightSideWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  HeaderTitleWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  HeaderDetailsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
  `,
  HeaderDetailsLabel: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,

  Checkbox: styled(Checkbox)`
    &.Mui-checked {
      color: ${AppCSS.colors.primaryGreenGoth} !important;
    }
    color: ${AppCSS.colors.greens.green60} !important;
    width: 20px;
    height: 20px;
  `,
  CheckboxLabel: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,

  BodyContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;
  `,
  ButtonsWrapper: styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  `,
  SecondaryButton: styled(AppCSS.buttonStyles.SecondaryButtonStyle)``,
};
